import React, {useContext} from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterContext} from '@ionic/react';
import { Route, Redirect, Switch} from 'react-router';
import { home, scanOutline, personCircleOutline} from 'ionicons/icons';

import '../assets/scss/MainTabs.scss'

import About from './About';
import Home from './Home';
import MerchantDetail from './MerchantDetail';
import PaymentPage from './PaymentPage';
import PaymentProcessor from './PaymentProcessor';
import PaymentDirectVerify from './PaymentDirectVerify';
import scanCode from '../util/scanner';
import UserProfile from './UserProfile'
import RewardPointsHistory from './RewardPointsHistory';
import RewardTransactionHistory from './RewardTransactionHistory';


interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {
  const ionRouterContext = useContext(IonRouterContext);
  
  async function handleScanner(){
    let redirect_path = await scanCode();
    ionRouterContext.push(redirect_path, 'none');
    return
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Redirect exact path="/tabs" to="/tabs/home" />
          {/*
            Using the render method prop cuts down the number of renders your components will have due to route changes.
            Use the component prop when your component depends on the RouterComponentProps passed in automatically.
          */}
          <Route path="/tabs/home" component={Home} exact={true} />
          <Route path="/tabs/about" render={() => <About />} exact={true} />
          <Route path="/tabs/profile" component={UserProfile} exact={true} />
          <Route path="/tabs/merchants/:id" component={MerchantDetail} exact={true}/>
          <Route path="/tabs/payments/pay/:merchantUid" component={PaymentPage} exact={true} />
          <Route path="/tabs/payments/process/:statusCode/:paymentID" component={PaymentProcessor} exact={true} />
          <Route path="/tabs/payments/direct/verify/:statusCode/:paymentID/:paymentOTP" component={PaymentDirectVerify} exact={true} />
          <Route path="/tabs/reward-points" render={()=> <RewardPointsHistory />} exact={true} />
          <Route path="/tabs/reward-history" render={()=> <RewardTransactionHistory />} exact={true} />
        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/home">
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton className='scanner-wrapper'>
          <div className="scanner-button" onClick={()=> handleScanner()}>
            <IonIcon icon={scanOutline}  />
          </div>
        </IonTabButton>
        
        <IonTabButton tab="profile" href="/tabs/profile">
          <IonIcon icon={personCircleOutline} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;