import React, { useEffect, useState } from 'react';
import { IonPage,  IonCardContent, IonButton} from '@ionic/react';
import * as selectors from '../data/selectors';
import { connect } from '../data/connect';

import { RouteComponentProps, withRouter } from 'react-router';

import '../assets/scss/PaymentProcessor.scss';
import axios from 'axios';

// fetch environment
import {morrApiURL} from '../envReader';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  statusCode: string
  paymentID: string
  authToken?: string
}

interface DispatchProps {}

type PaymentProps = OwnProps & StateProps & DispatchProps;

const PaymentProcessor: React.FC<PaymentProps> = ({ statusCode, paymentID, authToken}) => {
  const [paymentInformation, setPaymentInformation] = useState<any>(null)
  const [showContent, setShowContent] = useState(false)
  const [showContinueButton, setShowContinueButton] = useState('continue')
  
  useEffect(()=>{
    const paymentStatus: Record<string, string> = {
      '4200': 'PAYMENT_SUCCESSFUL',
      '4201': 'PAYMENT_FAILED',
      '4202': 'PAYMENT_PENDING',
      '4203': 'PAYMENT_UNVERIFIED',
      '4204': 'PAYMENT_REJECTED',
    }
  
    let ApiTransactionStatus: Record<string, string> = {
      0: '4200',
      1: '4201',
      2: '4202',
      3: '4203',
      4: '4204',
    }
  
    type paymentDetail = {
      thumb: string,
      title: string
      message: string
    }

    const paymentData: Record<string, paymentDetail> = {
      'PAYMENT_SUCCESSFUL': {
        thumb: 'https://freeiconshop.com/wp-content/uploads/edd/checkmark-flat.png',
        title: 'Payment successful',
        message: 'Your payment successfully completed.'
      },
      'PAYMENT_FAILED': {
        thumb: 'https://cdn4.iconfinder.com/data/icons/metaphors-pack/64/fail-mark-wrong-no-cross-denied-128.png',
        title: 'Payment failed',
        message: 'Your transaction has been failed. Any money deducted will be refunded back within 5-7 working days.'
      },
      'PAYMENT_PENDING': {
        thumb: 'https://icons.veryicon.com/png/128/miscellaneous/digital-couplet/unverified.png',
        title: 'Payment pending',
        message: 'Your transaction is still processing. Please check back after some time.'
      },
      'PAYMENT_REJECTED': {
        thumb: 'https://cdn4.iconfinder.com/data/icons/metaphors-pack/64/fail-mark-wrong-no-cross-denied-128.png',
        title: 'Payment Rejected',
        message: 'Your transaction has been rejected by the merchant. Please try again.'
      },
    }

    let source = axios.CancelToken.source();
    let status = paymentStatus[statusCode]
    let paymentDetails = paymentData[status]
    
    axios.post(`${morrApiURL}/payments/verify/${paymentID}`, null, {
      headers: {
        "X-Auth-Token": authToken
      }
    }).then(function (response) {
      // setPaymentAPIInformation(response.data.data);
      let data = response.data.data;
      let transaction_status = ApiTransactionStatus[data.TxnStatus];
      status = paymentStatus[transaction_status]
      paymentDetails = paymentData[status];
      
      if (transaction_status === "4200" && data.earned_points > 0){
        let textMessage = `You have received ${data.earned_points} ${data.merchant.name} points for transaction id ${paymentID}`
        paymentDetails.message = textMessage
      }
      setPaymentInformation(paymentDetails)
      setShowContent(true)
      setTimeout(function(){
        setShowContinueButton('show')
      }, 5000)
      
    }).catch((err)=>{
      console.log("error while pulling payment info", err, "\nsetting default", paymentDetails);
      setPaymentInformation(paymentDetails)
      setShowContent(true);
      setTimeout(function(){
        setShowContinueButton('show')
      }, 5000)
    })


    return () => {
      source.cancel("let's wrap up");
      setPaymentInformation(null);
      setShowContent(false)
    }
  }, [statusCode, paymentID, setShowContinueButton, authToken])

  return (
      <IonPage id="PaymentProcessor">
          {showContent ? <IonCardContent>
            <h3 className='morr--heading title'> {paymentInformation?.title}</h3>
            <div className="transaction-info ">
              <div className="flex w-full justify-center mb-4">
                <img className="w-1/2 h-1/2" src={paymentInformation?.thumb} alt={paymentInformation?.title} title={paymentInformation?.title} />
              </div>
              <div className='flex w-full justify-center mb-4 text-lg'>
                {paymentInformation?.message}
              </div>
              <div className={`redirect-button flex w-full justify-center mb-4 ${showContinueButton}`}>
                <IonButton expand="full" shape="round" fill="outline" routerLink='/tabs/home' routerDirection={"back"}>Continue</IonButton>
              </div>
            </div>
          </IonCardContent>: <div className="flex justify-center items-center w-full">Payment Confirmation Page</div>}
      </IonPage>
  );
};
    
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    statusCode: selectors.getParamByName(state, OwnProps, 'statusCode'),
    paymentID: selectors.getParamByName(state, OwnProps, 'paymentID'),
    authToken: state.user.authToken,
  }),
  component: withRouter(PaymentProcessor)
})