import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const HAS_LOGGED_IN = 'hasLoggedIn';
const USERNAME = 'username';
const AUTH_TOKEN = 'authtoken';
const REFRESH_TOKEN = 'refreshtoken';

export const getConfData = async () => {
  
  const data = {}
  return data;
}

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: USERNAME }),
    Storage.get({ key: AUTH_TOKEN }),
    Storage.get({ key: REFRESH_TOKEN }),
  ]);

  const isLoggedin = await response[0].value === 'true';
  const username = await response[1].value || undefined;
  const authToken = await response[2].value || '';
  const refreshToken = await response[3].value || '';

  const data = {
    isLoggedin,
    username,
    authToken,
    refreshToken
  }
  return data;
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
}


export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
}

export const setAuthTokenData = async (authToken?: string) => {
  if (!authToken){
    await Storage.remove({key: AUTH_TOKEN})
  } else {
    await Storage.set({ key: AUTH_TOKEN, value: authToken})
  }
}

export const setRefreshTokenData = async (refreshToken?: string) => {
  if (!refreshToken){
    await Storage.remove({key: REFRESH_TOKEN})
  } else {
    await Storage.set({ key: REFRESH_TOKEN, value: refreshToken})
  }
}