import axios from 'axios';


export const getAuthToken = function(){
    return "some-token"
}


export function rePopulateAccessToken(authToken?:string){
    console.log("Setting Default Auth token for all requests", authToken)
    if (!authToken){
        console.log("Delete auth token ")
        delete axios.defaults.headers.common["X-Auth-Token"];
        return
    }
    axios.defaults.headers.common["X-Auth-Token"] = authToken
}

