import { IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import React from 'react';

const NotFound: React.FC = () => {
  return (
   <IonPage id="404Page">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/tabs/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
            <h3 className='morr--heading title text-center text-3xl'> Morr is now available on iOS and Android </h3>

            <div className="w-full pt-4 pb-1 px-8 mb-6 mt-40 text-gray-50 items-center">
                <div className='flex mb-4 text-base font-medium justify-items-center'>
                    <div className="w-full sm:w-1/2 justify-start text-left">
                        <a target="_blank" rel="nourlopener noreferrer" title="Download on App Store"
                            href="https://apps.apple.com/my/app/morr/id1544728152">
                            <img src="assets/img/stores/app-store.png" title="Download on App Store" alt="Download on App Store" style={{"width":"57%", "margin": "0 auto"}} />
                        </a>
                        
                    </div>
                    <div className="w-full sm:w-1/2 justify-end text-right text-lg">
                    <a target="_blank" rel="nourlopener noreferrer" title="Get it on Google Play Store"
                            href="https://play.google.com/store/apps/details?id=io.morr.deals">
                        <img src="assets/img/stores/google-play.png" title="Get it on Google Play Store" alt="Get it on Google Play Store" style={{"width":"65%", "margin": "0 auto"}} />
                        </a>
                    </div>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default NotFound;