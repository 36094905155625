import React, { useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';
import NotFound from './components/NotFound';
import LandingPage from './pages/LandingPage'
import MainTabs from './pages/MainTabs';
import { setIsLoggedIn, setUsername, setAuthToken, loadUserData, setRefreshToken } from './data/user/user.actions';

import { Plugins } from '@capacitor/core';

import axios from "axios";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './assets/scss/global.scss';

import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import {rePopulateAccessToken} from './util/authentication';

// fetch environment
import {morrApiURL} from './envReader';


const App: React.FC = () => {
  return (
    <AppContextProvider>
      <MorrAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  authToken?: string;
  refreshToken?: string;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setAuthToken: typeof setAuthToken;
  setRefreshToken: typeof setRefreshToken;
}

interface MorrAppProps extends StateProps, DispatchProps { }

const MorrApp: React.FC<MorrAppProps> = ({ darkMode, setIsLoggedIn: setIsLoggedInAction, setUsername, setAuthToken:setAuthTokenAction, setRefreshToken:setRefreshTokenAction, isAuthenticated, authToken, refreshToken,  loadUserData}) => {

  console.log("Reading Morr API url from APP", morrApiURL, process.env.VERCEL_GIT_COMMIT_REF)
  const { Geolocation } = Plugins;

  function flattenObject(_obj:any){
    let coords = ''
    for (var key in _obj) {
      coords += `${_obj[key]},`
    }
    return coords.slice(0, -1);
  }

  Geolocation.getCurrentPosition().then((coordinates)=> {
    console.log("Add Geo Header", coordinates)
    axios.interceptors.request.use(
      config => {
        if (coordinates) {
            config.headers['X-GEO'] = flattenObject(coordinates.coords);
        }
        return config;
      },
      error => {
          Promise.reject(error)
      });
    
  }).catch(err=> {
    console.log("Add Geo Header - Err", err.message, err.code)
  });
  
  async function revalidate(){
    console.log("Revalidating for existing auth token", authToken, refreshToken);
 
    for (let i = 0; i < 3; i++) {
        let status = await axios.post(`${morrApiURL}/users/re-validate`, null, {headers: {"X-Auth-Token": authToken}}).then(res=> {
            setIsLoggedInAction(true);
            return true
        }).catch(err=> {
            console.log("error while revalidate", err.response);
            let status = true
            if (err?.response?.data?.detail === "Signature has expired"){
                console.log("Generating a new Token with Refresh");

                axios.post(
                    `${morrApiURL}/users/refresh`, 
                    null,
                    {headers: {"X-Auth-Token": refreshToken}}
                ).then((response)=> {
                    setAuthTokenAction(response.data.access_token);
                    setIsLoggedInAction(true);
                }).catch((err) => {
                  setAuthTokenAction(undefined);
                  setRefreshTokenAction(undefined);
                  setIsLoggedInAction(false);
                  console.log("failed to refresh token", err);
                  status = false;
                })
            }else {
                status = false;
                setIsLoggedInAction(false);
                console.log("error while revaliding", err);
            }
            return status
        });
        if (status){
            return true
        }
    }
    return false
  }

  if (isAuthenticated && authToken){
    rePopulateAccessToken(authToken);
  }

  useEffect(()=>{
    loadUserData();
    if (isAuthenticated && authToken){
      revalidate()
    }
    // eslint-disable-next-line
  }, [setAuthTokenAction, setIsLoggedInAction, authToken])

  return (
    (
        <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
          <IonReactRouter>
            <IonContent className="main">
              <Menu />
              <IonRouterOutlet id="main">
                {/*
                We use IonRoute here to keep the tabs state intact,
                which makes transitions between tabs and non tab pages smooth
                */}

                <Route path="/" render={(props) => !(isAuthenticated && authToken) ? <LandingPage {...props} /> : <Redirect to="/tabs/home" />} exact={true} />

                {/* <Route path="/" component={LandingPage} exact={true} /> */}
                <Route path="/tabs" render={() => <MainTabs />} />
                <Route component={NotFound} />
              </IonRouterOutlet>
            </IonContent>
          </IonReactRouter>
        </IonApp>
      )
  )
}

export default App;

const MorrAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    authToken: state.user.authToken,
    refreshToken: state.user.refreshToken,
  }),
  mapDispatchToProps: {loadUserData, setIsLoggedIn, setUsername, setAuthToken, setRefreshToken },
  component: MorrApp
});
