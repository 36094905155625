import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonItem, IonToolbar } from '@ionic/react';
import { receiptOutline } from 'ionicons/icons';
import React, { useEffect, useState, useRef} from 'react';
import Chart from 'chart.js';

import '../assets/scss/RewardPointsHistory.scss';
import getPointsProgressClass from '../util/merchantPoints';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import axios from 'axios';
import 'chartjs-plugin-colorschemes';

// fetch environment
import {morrApiURL} from '../envReader';


interface RewardPointsHistoryProps { }

const RewardPointsHistory: React.FC<RewardPointsHistoryProps> = () => {
    // Chart.platform.disableCSSInjection = true;

    const chartWrapper:any = useRef(null)
    const [showChart, setShowChart] = useState(false);
    const [rewardList, setRewardList] = useState<any>([])
    const [selectedCanvasValue, setSelectedCanvasValue] = useState(0);
    const [canvasColor, setCanvasColor] = useState('#e5a68c');



    const defaultColor = '#e5a68c';


    useEffect(()=> {
        let source = axios.CancelToken.source();

        let totalPoints:number = 0;
        let morrPoints:number = 0;
        let rewardPointsList:any = [];

        function getRewardList(){
            axios.get(`${morrApiURL}/rewards/points`, {
            }).then(function (response) {
                setRewardList(response.data.data);
                rewardPointsList = response.data.data
                totalPoints = response.data.total_points;
                morrPoints = response.data.morr_points;
                drawWalletChart(buildData());
            }).catch(function(err){
                console.error("REWARD_TRANSACTION_HISTORY: "+ err);
            })
        }
        
        getRewardList()
        
        let hoverColor:any = []

        function getSchemeColor(colors:any){
            hoverColor = colors;
            return colors
        }
        
        function buildData(){
            let chartData:any = []
            let chartLabels: any = []

            if (morrPoints > 0){
                chartData.push(morrPoints);
                chartLabels.push("Morr")
            }
            rewardPointsList.map((reward:any) => {
                console.log("Hey Reward", reward)
                chartData.push(reward.points);
                chartLabels.push(reward.merchant.name)
                return null;
            })

            let data = {
                datasets: [{
                    data: chartData,
                    backgroundColor: hoverColor,
                    borderWidth: 0,
                    hoverBorderWidth: 10,
                    hoverBackgroundColor: hoverColor,
                    hoverBorderColor: hoverColor,
                
                }],
                labels: chartLabels
                
            };
            return data;
        }


        function drawWalletChart(data:any, isChartDrawn:boolean=false){
            let options = {
                plugins: {
                    colorschemes: {
                        scheme: 'brewer.PuOr5',
                        fillAlpha: 0.1,
                        custom: getSchemeColor
                    }
                },
                animation: {
                    animateScale:true
                },
                cutoutPercentage: 80,
                legend: {
                    display: false
                },
                onClick: function(event:any, legendItem:any){
                    if (legendItem.length > 0){
                        let chart = legendItem[0]._chart;
                        let index = legendItem[0]._index;
                        let points = chart.data.datasets[0].data[index];
                        let color = chart.data.datasets[0].hoverBackgroundColor[index];
                        setCanvasColor(color)
                        setSelectedCanvasValue(points)
                    }else{
                        setSelectedCanvasValue(totalPoints);
                        setCanvasColor(defaultColor)
                    }
                }
            }

            let ctx = chartWrapper.current.getContext('2d');

            new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: options
            });
            setSelectedCanvasValue(totalPoints);
            setCanvasColor(defaultColor)
            setShowChart(true);

            if (!isChartDrawn){
                drawWalletChart(buildData(), true);
            }
        }

        return () => {
            setRewardList([]);
            source.cancel("wrap up reward points")
            // chart.destroy();
        }

    }, [])
   

    return (
        <IonPage id="rewards-page">
            <IonContent>
                <div className="base-wrapper">
                    <IonHeader className="ion-no-border">
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonBackButton defaultHref="/tabs/home" />
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton routerLink="/tabs/reward-history" routerDirection="forward">
                                    <IonLabel>History</IonLabel>
                                    <IonIcon icon={receiptOutline}></IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                
                    <div className="morr--heading page-title"> Overview of my points</div>
                    <div className={`wallet-chart-wrapper ${showChart? 'show': 'hide'}`}>
                        <canvas id="walletPieChart" ref={chartWrapper}></canvas>
                        {selectedCanvasValue > 0 && <div className="selected-value" style={{color: canvasColor}}>{selectedCanvasValue}</div>}
                    </div>
                </div>
                
                <div className="merchant-list-wrapper">
                    <div className="merchant-list">
                        {rewardList.length < 1  && <>
                            <div className="text-lg text-center">
                                <p>No records available</p>
                                <p className="font-semibold">Shop more to earn rewards</p>
                            </div>
                        </>}
                        {rewardList.map((reward:any) => (
                            <IonItem detail={false} mode="ios" key={`${reward.merchant.merchant_id}-${reward.merchant.name}`} routerLink={`/tabs/merchants/${reward.merchant.merchant_id}`}>
                                <div className="merchant-wrapper rounded-lg shadow-sm">
                                    <div className="morr--heading merchant-title"> {reward.merchant.name}</div>
                                    <div className="merchant-thumb mb-4">
                                        <img src={(reward.merchant.images.profile.length > 0) ? reward.merchant.images.profile[0] : ''} alt={reward.merchant.name}/>
                                        <span className='morr--normal-text'>{reward.merchant?.next_level_info?.heading || "Shop more for morr rewards"}</span>
                                    </div>
                                    <div className='points-progress'>
                                        {reward.merchant.point_tiers && <ProgressBar
                                            filledBackground="linear-gradient(90deg, rgba(228,144,108,1) 57%, rgba(255,206,184,1) 100%)"
                                            percent={getPointsProgressClass(reward.current_level)}
                                            >
                                            <Step transition="scale">
                                                {() => (<div className={`transitionStep ${getPointsProgressClass(reward.current_level) >= 0  ? "accomplished" : null}`}></div>)}
                                            </Step>
                                            <Step transition="scale">
                                                {() => (<div className={`transitionStep progressbar bronze-level ${getPointsProgressClass(reward.current_level) >=30 ? "accomplished" : null}`}></div>)}
                                            </Step>
                                            <Step transition="scale">
                                                {() => (<div className={`transitionStep progressbar silver-level ${getPointsProgressClass(reward.current_level) >= 50 ? "accomplished" : null}`}></div>)}
                                            </Step>
                                            <Step transition="scale">
                                                {() => (<div className={`transitionStep progressbar gold-level ${getPointsProgressClass(reward.current_level) >=100 ? "accomplished" : null}`}></div>)}
                                            </Step>
                                        </ProgressBar>}
                                    </div>
                                </div>
                            </IonItem>
                        ))}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default RewardPointsHistory;
