import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonToolbar } from '@ionic/react';
import { pieChartSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import Collapse, {Panel} from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import axios from 'axios';

import '../assets/scss/RewardTransactionHistory.scss';

// fetch environment
import {morrApiURL} from '../envReader';

interface RewardTransactionHistoryProps { }

const RewardTransactionHistory: React.FC<RewardTransactionHistoryProps> = () => {
    const [rewardTransactions, setRewardTransactions] = useState<any>([])

    useEffect(()=>{
        let source = axios.CancelToken.source();

        axios.get(`${morrApiURL}/rewards/history`, {
        }).then(function (response) {
            console.log("rewards", response)
            setRewardTransactions(response.data.data);
        }).catch(function(err){
            console.error("REWARDS_HISTORY: "+ err);
        })
        return () => {
            setRewardTransactions([]);
            source.cancel("wrap up rewards transaction history");
        }
    }, [])
    return (
        <IonPage id="transactions-page">
            <IonContent>
                <div className="header-wrapper">
                    <IonHeader className="ion-no-border">
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonBackButton defaultHref="/tabs/home" />
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton routerLink="/tabs/reward-points" routerDirection="back">
                                    <IonLabel>My Points</IonLabel>
                                    <IonIcon icon={pieChartSharp}></IonIcon>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <div className="morr--heading page-title"> Transaction History</div>
                </div>
                <div className="content-wrapper">
                    <div className="transactions-list">
                        {rewardTransactions.length < 1  && <>
                            <div className="text-lg text-center">
                                <p>No records available</p>
                                <p className="font-semibold">Shop more to earn rewards</p>
                            </div>
                        </>}
                        <Collapse accordion={true}>
                            {rewardTransactions.map((rewardHistory:any)=>
                                <Panel 
                                    key={rewardHistory.reward_id} 
                                    header={<div className="reward-title">{rewardHistory.title || rewardHistory.merchant.name}</div>} 
                                    extra={<div className="reward-points">{rewardHistory.points}</div>} 
                                    headerClass="my-header-class">
                                    {rewardHistory.description}
                                </Panel>
                            )}
                        </Collapse>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default RewardTransactionHistory;