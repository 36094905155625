import React, {useState, useContext, useEffect} from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonBackButton, IonLabel, IonInput, IonRouterContext, IonButton, IonCheckbox, IonItem, IonRouterLink, IonIcon} from '@ionic/react';


import * as selectors from '../data/selectors';
import { connect } from '../data/connect';

import { RouteComponentProps, withRouter } from 'react-router';

import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser";

import { Merchant } from '../models/Merchant';
import axios from 'axios';
import {rePopulateAccessToken} from '../util/authentication';

import '../assets/scss/PaymentPage.scss';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import OtpInput from 'react-otp-input';

import { setIsLoggedIn, setUsername, setAuthToken, setRefreshToken } from '../data/user/user.actions';
import { globeOutline, walletOutline } from 'ionicons/icons';

// fetch environment
import {morrApiURL} from '../envReader';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  merchantUid: string;
  isAuthenticated: boolean;
  authToken?: string;
};

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setAuthToken: typeof setAuthToken;
  setRefreshToken: typeof setRefreshToken;
}

type PaymentPageProps = OwnProps & StateProps & DispatchProps;



const PaymentPage: React.FC<PaymentPageProps> = ({merchantUid, isAuthenticated, authToken, setIsLoggedIn, setUsername, setAuthToken:setAuthTokenAction, setRefreshToken: setRefreshTokenAction}) => {
  
  const ionRouterContext = useContext(IonRouterContext);

  const [amount, setAmount] = useState<number>(0.0);
  const [total, setTotal] = useState<number>(0.0);
  const [discount, setDiscount] = useState<number>(0.0);

  // const [pointToBeGiven, setPointToBeGiven] = useState<number>(5);
  // const [sliderPoints, setSliderPoints] = useState<number>(0);
  // const [maxPoints, setMaxPoints] = useState<number>(0);
  
  // const [isPointSliderDisabled, setIsPointSliderDisabled] = useState(true)

  const [customerIP, setCustomerIP] = useState('-')
  const [orderID, setOrderID] = useState('')
  const [merchant, setMerchant] = useState<Merchant|any>(null)
  const [redeemPoints, setRedeemPoints] = useState<boolean>(false)
  const [pointsCashback, setPointsCashback] = useState<number>(0)

  // Login Data
  const [allowResendOtp, setAllowResendOtp] = useState(false);
  const [resendTimer, setResendTimer] = useState(10);
  const [resentCount, setResentCount] = useState(1);
  const [mobileNumber, setMobileNumber] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('')
  const [otpCode, setOtpCode] = useState<string>('')
  const [loginScreen, setLoginScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [userWalletData, setUserWalletData] = useState<any>(null);
  const [walletPoints, setWalletPoints] = useState(0);

  const [showPaymentOptions, setShowPaymentOptions] = useState('');
  const [paymentOptions, setPaymentOptions] = useState<any>([]);

  let defaultTimerCount = 10
  let resentTimerID:NodeJS.Timeout

  
  useEffect(()=>{
      let source = axios.CancelToken.source();

      function getMerchants(){
        axios.get(`${morrApiURL}/merchants/${merchantUid}`).then(function (result) {
          setMerchant(result.data.data);
        }).catch(function(err){
          if (err.response.status_code === 404){
            alert("Merchant not found.")
          }
          console.error("MERCHANT_DETAILS: "+ err);
        })
      }

      function getWallet(){
        axios.get(`${morrApiURL}/rewards/points/${merchantUid}`).then(function(response){
          // set wallet data;
          setUserWalletData(response.data.data);
          setWalletPoints(response.data.data.points.total);
          // setIsLoggedIn(true)
        }).catch(function(err){
          console.error("WALLET_MERCHANT_POINTS", err);
          setAuthTokenAction('')
        })
      }

      function getPayementOptions(){
        axios.get(`${morrApiURL}/payments/options`).then(function (result) {
          setPaymentOptions(result.data.data);
        }).catch(function(err){
          console.error("PAYMENT_OPTIONS: "+ err);
        })
      }

      getMerchants()
      if (isAuthenticated){
        getWallet();
        getPayementOptions()
      }
      
      return () => {
        source.cancel("wrap up payments")
        setMerchant(null);
        setAmount(0);
        setTotal(0);
        setRedeemPoints(false);
        setPointsCashback(0)
        setOrderID('')
        setUserWalletData(null);
        setLoginScreen(false);
        setPaymentOptions([])
      }
  }, [merchantUid, isAuthenticated, setAuthTokenAction])


  function secondsToTime(secs:number){
    return new Date(secs * 1000).toISOString().substr(11, 8)
  }

  function transitionState(from:CallableFunction, to:CallableFunction){
    from(false);
    to(true);
  }

  function countDown() {

    defaultTimerCount -= 1;
    setResendTimer(defaultTimerCount)

    if (defaultTimerCount === 0) { 
      removeCountDown()
      setAllowResendOtp(true)
    }
}

function removeCountDown(){
    clearInterval(resentTimerID);

    if (defaultTimerCount <= 0){
        defaultTimerCount = 10
    }
}

  function validateAndUpdatePhone(country:any, phone:any){
    if (phone.length > 4){
        setMobileNumber(phone);
        setCountryCode(country.dialCode)
    }
  }

  function sendOtp(resend=false){
    
    // hit api to send otp
    let phoneWithoutCode = mobileNumber.slice(countryCode.length, mobileNumber.length)
    
    axios.post(
        `${morrApiURL}/users/login`,
        {
            "code": countryCode,
            "mobile": phoneWithoutCode
        },
    ).then(res => {
        if (!resend){
            setLoginScreen(false)
            setOtpScreen(true)
        }
        setOtpCode('')
        setAllowResendOtp(false)
        setResendTimer(defaultTimerCount)
        setResentCount(resentCount+1)
        resentTimerID = setInterval(countDown, 1000)
        setShowResendButton(true)
    }).catch(err => {
        console.log("request failed", err)
        alert(err.detail)
    });
  }

  function verifyOtp(){

    let phoneWithoutCode = mobileNumber.slice(countryCode.length, mobileNumber.length)
    axios.post(
        `${morrApiURL}/users/verify`,
        {
            "code": countryCode,
            "mobile": phoneWithoutCode,
            "otp": otpCode
        },
    ).then((res:any) => {
        setLoginScreen(true)
        setOtpScreen(false)
        setMobileNumber('')
        setOtpCode('')
        setAuthTokenAction(res.data.access_token);
        setRefreshTokenAction(res.data.refresh_token);
        rePopulateAccessToken(res.data.access_token)
        setIsLoggedIn(true)
        // ionRouterContext.push('/tabs/home', 'none');
    }).catch(err => {
        console.log("request failed", err)
        setOtpCode('')
        alert(err.detail)
    });
  }



  function resetState(){
    setMerchant(null);
    setAmount(0);
    setTotal(0);
    setRedeemPoints(false);
    setPointsCashback(0)
    setOrderID('')
  }

  if (!merchantUid || !merchant){
    return <div> Loading ... </div>
  }
  
  let options: InAppBrowserOptions = {
    location : 'yes',//Or 'no' 
    hidden : 'no', //Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'yes',//Android only ,shows browser zoom controls 
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', //Android only 
    closebuttoncaption : 'Close', //iOS only
    disallowoverscroll : 'no', //iOS only 
    toolbar : 'yes', //iOS only 
    enableViewportScale : 'no', //iOS only 
    allowInlineMediaPlayback : 'no',//iOS only 
    presentationstyle : 'fullscreen',//iOS only 
    fullscreen : 'yes',//Windows only
  };

  type paymentDataType = {
    "PymtMethod": string,
    "Amount": string,
    "CustIP": string,
    "CustName": string,
    "CustEmail": string,
    "CustPhone": string,
    "PaymentDesc": string,
    "redeemPoints": boolean
    "IssuingBank"?: string
  }

  type payloadType = {
    order: object,
    payment_data: object
  }

  type orderType ={
    order_id?: any,
    merchant_id: string
  }

  function getCustomerInfo(){
    type customerType = {
      name: string,
      email: string,
      phone: string,
    } 
    let customer:customerType =  {
      name: userWalletData?.user.first_name,
      email: userWalletData?.user.email,
      phone: userWalletData?.user.mobile_number
    }
    return customer
  }

  function makePayment(PymtMethod="ANY", issuingBank=""){
    let customer = getCustomerInfo()
  
    let paymentData:paymentDataType = {
      "PymtMethod": PymtMethod,
      "Amount": amount.toFixed(2),
      "CustIP": customerIP,
      "CustName": customer.name || 'Morr User',
      "CustEmail": customer.email || 'ghlemail@morr.my',
      "CustPhone": customer.phone,
      "PaymentDesc": `Paying to ${merchant.name}`,
      "redeemPoints": redeemPoints
    }

    if (issuingBank){
      paymentData["IssuingBank"] = issuingBank
    }
    
    let orderData:orderType = {
      merchant_id: merchantUid
    }

    if (orderID !== '') {
      orderData.order_id = orderID;
    }

    let payload:payloadType = {
      order: orderData,
      payment_data: paymentData
    }

    let paymentApiUrl = `${morrApiURL}/payments/prepare`;
    axios.post(paymentApiUrl, payload).then(function (response) {
      let result = response.data
      if (!result.hasOwnProperty('destination')){
        alert(`Something went wrong !\n${result.detail}`)
        return ;
      }
      if (result.type === "native"){
        resetState(); // destroy order id
        let destination = new URL(result.destination);
        console.log("Redirecting to ", destination.pathname)
        ionRouterContext.push(destination.pathname, 'forward', "replace");
        return
      }
      let browser = InAppBrowser.create(result.destination, '_blank', options);

      if (result.order_id !== '' && result.order_id !== undefined && result.order_id !== null){
        setOrderID(result.order_id);
      }
      
      browser.on('loadstop').subscribe(event => {
        let check_url = event.url.indexOf('/payments/process/') !== -1
        if (check_url){
          resetState(); // destroy order id

          const url = new URL(event.url);
          browser.close();
          ionRouterContext.push(url.pathname, 'forward', "replace");
          return
        }
      })
    })
    .catch (function (error) {
      console.log('Unable to make payment: ', error);
    });
  }

  if (!merchantUid){
    ionRouterContext.push('/', "none", "replace");
  }

  function validateAndSetAmount(amount:number){
    // setPointToBeGiven(10);
    setCustomerIP("127.0.0.1");

    if (isNaN(amount)){
      return;
    }

    // if (amount > 0){
    //   setIsPointSliderDisabled(false);
    // }

    setAmount(amount);
    // setTotal(amount);
    applyRedeemPoints(redeemPoints)

    // let points = amount < walletPoints ? amount: walletPoints;
    // setMaxPoints(points);
    
  }

  // function calculateAmountToBePaid(pointsToBeRedeemed:number){
  //   setTotal((amount - pointsToBeRedeemed));
  //   setSliderPoints(pointsToBeRedeemed);
  // }


  function getUserLevel(){
    return userWalletData?.points.detail[merchantUid]?.current_level || "bronze"
  }


  function applyRedeemPoints(isRedeemPoints:boolean){
    const loyaltyPointValue:number = 10
    const pointsInWallet = userWalletData?.points.total;
    setDiscount(0);
    let payableTotal = amount
    if (isRedeemPoints){
      let requiredPoints:number = Math.floor(amount * loyaltyPointValue)
      let pointsUsed = pointsInWallet >= requiredPoints ? requiredPoints : pointsInWallet;
      payableTotal = (amount - (pointsUsed / loyaltyPointValue));

      if (!isNaN(pointsUsed)){
        setDiscount(pointsUsed);
      }
    }

    if (!isNaN(payableTotal)){
      setTotal(payableTotal);
    }
    
    setRedeemPoints(isRedeemPoints);

    if (merchant.point_tiers){
      
      let userLevel = getUserLevel()
      console.log("Calculate cashback", merchant.point_tiers, userLevel, payableTotal, loyaltyPointValue)
      if (userLevel){
        let levelInfo = merchant.point_tiers[userLevel];
        let cashbackPointsToBeAwarded = 0;
        if (levelInfo.type === "percentage"){
          cashbackPointsToBeAwarded = Math.floor((payableTotal * ((merchant.point_tiers[userLevel]?.cashback || 0)/100)) * loyaltyPointValue);
        }else if (levelInfo.type === "flat"){
          cashbackPointsToBeAwarded = Math.floor(merchant.point_tiers[userLevel]?.cashback * loyaltyPointValue);
        }
        setPointsCashback(cashbackPointsToBeAwarded)
      }
    }
    
  }

  return (
    <IonPage id="PaymentPage">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/tabs/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="payments">
          	<h3 className='morr--heading title'> Payment Page </h3>
            <div className="info">
              <div className="thumb">
                <img src={merchant.images.profile[0]} alt={merchant.name}/>
              </div>
              {authToken ? <div className="user_response">
                {pointsCashback >= 1 && <div className='points-flag'>
                  You will receive {pointsCashback} {merchant.name} points
                </div>}
                <IonLabel className='price--label'>
                  Please enter the correct amount provided by {merchant.name|| 'merchant'}
                  <IonInput type="number" value={amount} max="10000" onIonChange={e => validateAndSetAmount(parseFloat(e.detail.value!))} className="price--input"></IonInput>
                </IonLabel>
                <div className="redeem-points">
                  <IonItem mode="ios" lines="none">
                    <IonLabel> Redeem all points</IonLabel>
                    <IonCheckbox slot="start" checked={redeemPoints} onIonChange={(e:any) => applyRedeemPoints(e.detail.checked)} />
                  </IonItem>
                </div>
                {/* <div className={isPointSliderDisabled ? 'hide': 'points--slider'}>
                  <IonRange pin={true} value={sliderPoints} max={maxPoints} onIonChange={e => calculateAmountToBePaid(e.detail.value as number)} disabled={isPointSliderDisabled} />
                </div> */}
                  <div className="w-full pt-4 pb-1 px-8 mb-6 text-gray-50" style={{"background": "#e5a68be0", "borderRadius": "1.5rem", "textShadow": "0px 0px #fff"}}>
                    <div className='flex mb-4 text-base font-medium'>
                      <div className="w-1/2 justify-start text-left">
                        Available Points
                      </div>
                      <div className="w-1/2 justify-end text-right text-lg">
                        {walletPoints}
                      </div>
                    </div>
                    <div className='flex mb-4 text-base font-medium'>
                      <div className="w-1/2 justify-start text-left">
                        Credits Used
                      </div>
                      <div className="w-1/2 justify-end text-right text-lg">
                        {discount}
                      </div>
                    </div>

                    <div className='flex mb-4 text-xl font-medium'>
                      <div className="w-1/2 justify-start text-left">
                        Pay Now
                      </div>
                      <div className="w-1/2 justify-end text-right">
                        RM {total.toFixed(2)}
                      </div>
                    </div>

                  </div>
                <div className="flex justify-around flex-initial flex-wrap break-words" style={{"border": "3px solid #d7d4cd", "borderRadius": "1.5rem"}}>
                   <IonItem disabled={!(amount > 0)} onClick={()=> setShowPaymentOptions('wallets')} className="min-w-min">
                    <IonIcon className="w-16 h-16" icon={walletOutline}></IonIcon>
                  </IonItem>
                   {/* <IonItem disabled={!(amount > 0)} className="min-w-min" onClick={() => makePayment("CC")}>
                    <IonIcon className="w-16 h-16" icon={cardOutline}></IonIcon>
                  </IonItem> */}
                   <IonItem disabled={!(amount > 0)} className="min-w-min" onClick={()=> setShowPaymentOptions('netbanking')}>
                    <IonIcon className="w-16 h-16" icon={globeOutline}></IonIcon>
                  </IonItem>
                </div>
                {(amount > 0 && showPaymentOptions) && <div className="flex justify-between flex-initial flex-wrap break-words payment-options">
                  {paymentOptions[showPaymentOptions]?.map((paymentOption:any) => (
                    <IonItem key={`payment-options-${paymentOption.IssuingBank}`} className="mt-8" onClick={() => makePayment(paymentOption.paymentMode, paymentOption.IssuingBank)}>
                      <img className="w-16" src={paymentOption.logo} alt={paymentOption.name} title={paymentOption.name} />
                    </IonItem>
                  ))}
                </div>}
                <div className='payments-total'>
                  <IonButton shape="round" expand="full" className='pay-by-cash' disabled={!(amount > 0)} 
                  onClick={() => makePayment("CASH")}>
                    <span className='action'> Pay by Cash </span> 
                  </IonButton>
                </div>

              </div> 
              : 
              <div className="login-wrapper">
                
                  {loginScreen && <div className='login-screen'>
                    <h3 className="morr--heading">Login to Continue</h3>
                    <PhoneInput
                        country={'my'}
                        value={mobileNumber}
                        onChange={(phone, country) => validateAndUpdatePhone(country, phone)}
                        placeholder={'Please enter your Mobile Number'}
                        specialLabel={'Mobile Number'}
                        inputClass="phone-number"
                        countryCodeEditable={false}
                        onlyCountries={["in", "my"]}
                    />
                    <IonButton className="morr-button" shape="round" expand="full" onClick={()=> sendOtp()} disabled={!mobileNumber}>Send Otp</IonButton>
                  </div>}
                  {otpScreen && <div className='otp-screen'>
                      <div className="otp-wrapper">
                          <h3> Enter Otp to continue</h3>
                          <small>An Otp has been sent {mobileNumber}</small>
                          <div className="otp-input-wrapper">
                              <OtpInput
                                  value={otpCode}
                                  onChange={setOtpCode}
                                  numInputs={6}
                                  separator={<span> &nbsp; &nbsp;</span>}
                                  inputStyle="otp-input"
                                  isInputNum={true}
                              />
                          </div>
                      
                          {showResendButton && <IonButton className="resend-otp" shape="round" fill="outline" expand="full" onClick={()=> sendOtp(true)} disabled={!allowResendOtp}>resend OTP {secondsToTime(resendTimer)}</IonButton>}
                          <div className="separator"></div>
                          <div className="confirm-terms">
                              by clicking LOGIN, you agree to our <a href="https://www.morr.my/terms-and-conditions" title="Terms and Conditions">Terms & conditions</a> and <a href="https://www.morr.my/privacy-policy" title="Privacy Policy">Privacy Policy</a> 
                          </div>
                          <IonButton className="morr-button" shape="round" expand="full" onClick={()=> verifyOtp()} disabled={!otpCode || otpCode.length < 6}> Login</IonButton>
                          <div className="wrong-phone-link">
                              <IonRouterLink onClick={()=> transitionState(setOtpScreen, setLoginScreen)}>Wrong phone number?</IonRouterLink>
                          </div>
                      </div>
                      
                  </div>}

              </div>
            }
            </div>
        </div>
        
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    merchantUid: selectors.getParamByName(state, OwnProps, 'merchantUid'),
    isAuthenticated: state.user.isLoggedin,
    authToken: state.user.authToken,
  }),
  mapDispatchToProps: {setIsLoggedIn, setUsername, setAuthToken, setRefreshToken },
  component: withRouter(PaymentPage)
})