import React, {useState} from 'react';
import {IonPage, IonContent, IonModal, IonButton, IonRouterLink} from '@ionic/react';


import '../assets/scss/LandingPage.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import OtpInput from 'react-otp-input';
import scanCode from '../util/scanner';

import { connect } from '../data/connect';
import { setIsLoggedIn, setUsername, setAuthToken, setRefreshToken } from '../data/user/user.actions';

import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import {rePopulateAccessToken} from '../util/authentication';

// fetch environment
import {morrApiURL} from '../envReader';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
    darkMode: boolean;
    isAuthenticated: boolean;
    authToken?: string;
    refreshToken?: string;
}

interface DispatchProps {
    setIsLoggedIn: typeof setIsLoggedIn;
    setUsername: typeof setUsername;
    setAuthToken: typeof setAuthToken;
    setRefreshToken: typeof setRefreshToken;
}

interface LandingPageProps extends OwnProps, StateProps,  DispatchProps { };

const APP_VERSION = "0.0.1"

const LandingPage: React.FC<LandingPageProps> = ({setIsLoggedIn:setIsLoggedInAction, history, setAuthToken:setAuthTokenAction, isAuthenticated, authToken, setUsername: setUsernameAction, setRefreshToken: setRefreshTokenAction, refreshToken}) => {
    const [loginModal, setLoginModal] = useState(false);
    const [allowResendOtp, setAllowResendOtp] = useState(false);
    const [resendTimer, setResendTimer] = useState(10);
    const [resentCount, setResentCount] = useState(1);
    const [mobileNumber, setMobileNumber] = useState<string>('')
    const [countryCode, setCountryCode] = useState<string>('')
    const [otpCode, setOtpCode] = useState<string>('')
    const [welcomeScreen, setWelcomeScreen] = useState(true)
    const [loginScreen, setLoginScreen] = useState(false);
    const [otpScreen, setOtpScreen] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);


    let defaultTimerCount = 10
    let resentTimerID:NodeJS.Timeout

    function transitionState(from:CallableFunction, to:CallableFunction){
        from(false);
        to(true);
    }

    function validateAndUpdatePhone(country:any, phone:any){
        if (phone.length > 4){
            setMobileNumber(phone);
            setCountryCode(country.dialCode)
        }
    }

    function secondsToTime(secs:number){
        return new Date(secs * 1000).toISOString().substr(11, 8)
    }
    
    function countDown() {

        defaultTimerCount -= 1;
        setResendTimer(defaultTimerCount)

        if (defaultTimerCount === 0) { 
          removeCountDown()
          setAllowResendOtp(true)
        }
    }
    
    function removeCountDown(){
        clearInterval(resentTimerID);

        if (defaultTimerCount <= 0){
            defaultTimerCount = 10
        }
    }

    function sendOtp(resend=false){
        // hit api to send otp
        let phoneWithoutCode = mobileNumber.slice(countryCode.length, mobileNumber.length)
        
        axios.post(
            `${morrApiURL}/users/login`,
            {
                "code": countryCode,
                "mobile": phoneWithoutCode
            },
        ).then(res => {
            if (!resend){
                setLoginScreen(false)
                setOtpScreen(true)
            }
            setOtpCode('')
            setAllowResendOtp(false)
            setResendTimer(defaultTimerCount)
            setResentCount(resentCount+1)
            resentTimerID = setInterval(countDown, 1000)
            setShowResendButton(true)
        }).catch(err => {
            alert(err.detail)
        });

    }

    function verifyOtp(){
 
        let phoneWithoutCode = mobileNumber.slice(countryCode.length, mobileNumber.length)
        axios.post(
            `${morrApiURL}/users/verify`,
            {
                "code": countryCode,
                "mobile": phoneWithoutCode,
                "otp": otpCode
            },
        ).then((res:any) => {
            setWelcomeScreen(true)
            setLoginScreen(false)
            setOtpScreen(false)
            setMobileNumber('')
            setOtpCode('')
            setAuthTokenAction(res.data.access_token);
            rePopulateAccessToken(res.data.access_token);
            setRefreshTokenAction(res.data.refresh_token);
            setIsLoggedInAction(true)
            history.push('/tabs/home', 'root');
        }).catch(err => {
            console.log("request failed", err)
            setOtpCode('')
            alert(err.detail);
        });
    }

    async function handleScanner(){
       let redirect_path = await scanCode();
       history.push(redirect_path, 'none');
    }
    
    return (
        <IonPage id="landing-page">
            <IonContent>
                <div className="logo-wrapper">
                    <img className='logo' src='/assets/icon/morr-white-transparent.png' alt="Morr rewards More"></img>
                </div>
                <div className="content-wrapper">
                    <div className="content">
                        {welcomeScreen && <div className="welcome-screen">
                            <h3 className="text-2xl font-bold mt-4"> Welcome to Morr</h3>
                            <p className="text-base">Select an option to get started with Morr</p>
                            <IonButton className="morr-button" shape="round" expand="full" onClick={()=> transitionState(setWelcomeScreen, setLoginScreen)}>Login / Register</IonButton>
                            <div className="or-button"><span>or</span></div>
                            <IonButton className="morr-button" shape="round" expand="full" onClick={()=> handleScanner()}>Scan</IonButton>
                        </div>}
                        {loginScreen && <div className='login-screen'>
                            <h3 className="text-2xl font-bold mt-4"> Welcome Back</h3>
                            <PhoneInput
                                country={'my'}
                                value={mobileNumber}
                                onChange={(phone, country) => validateAndUpdatePhone(country, phone)}
                                placeholder={'Please enter your Mobile Number'}
                                specialLabel={'Mobile Number'}
                                inputClass="phone-number"
                                countryCodeEditable={false}
                                onlyCountries={["in", "my"]}
                            />
                            <IonButton className="morr-button" shape="round" expand="full" onClick={()=> sendOtp()} disabled={!mobileNumber}>Send Otp</IonButton>
                        </div>}
                        {otpScreen && <div className='otp-screen'>
                            <div className="otp-wrapper">
                                <h3 className="text-2xl font-bold mt-4"> Enter Otp to continue</h3>
                                <small>An Otp has been sent {mobileNumber}</small>
                                <div className="otp-input-wrapper">
                                    <OtpInput
                                        value={otpCode}
                                        onChange={setOtpCode}
                                        numInputs={6}
                                        separator={<span> &nbsp; &nbsp;</span>}
                                        inputStyle="otp-input"
                                        isInputNum={true}
                                    />
                                </div>
                            
                                {showResendButton && <IonButton className="resend-otp" shape="round" fill="outline" expand="full" onClick={()=> sendOtp(true)} disabled={!allowResendOtp}>resend OTP {secondsToTime(resendTimer)}</IonButton>}
                                <div className="separator"></div>
                                <div className="confirm-terms">
                                    by clicking NEXT, you agree to our <a href="https://www.morr.my/terms-and-conditions" title="Terms and Conditions">Terms & conditions</a> and <a href="https://www.morr.my/privacy-policy" title="Privacy Policy">Privacy Policy</a> 
                                </div>
                                <IonButton className="morr-button" shape="round" expand="full" onClick={()=> verifyOtp()} disabled={!otpCode || otpCode.length < 6}> Next</IonButton>
                                <div className="wrong-phone-link">
                                    <IonRouterLink onClick={()=> transitionState(setOtpScreen, setLoginScreen)}>Wrong phone number?</IonRouterLink>
                                </div>
                            </div>
                            
                        </div>}
                    </div>
                    <div className="app-info">Morr+ version {APP_VERSION}</div>
                </div>
                
                <IonModal 
                    isOpen={loginModal} 
                    backdropDismiss={false} 
                    keyboardClose={false} 
                    swipeToClose={false} 
                    onDidDismiss={() => setLoginModal(false)}
                    cssClass='login'>
                    <p>This is modal content</p>
                    <IonButton onClick={() => setLoginModal(false)}>Close</IonButton>
                </IonModal>
                {/* <IonButton onClick={() => setLoginModal(true)}>Login</IonButton> */}
            </IonContent>
        </IonPage>
    )
}

export default connect<OwnProps, {}, DispatchProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        isAuthenticated: state.user.isLoggedin,
        authToken: state.user.authToken,
        refreshToken: state.user.refreshToken,
    }),
    mapDispatchToProps: {
      setIsLoggedIn,
      setAuthToken,
      setRefreshToken,
      setUsername
    },
    component: LandingPage
})