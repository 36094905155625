import React from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton } from '@ionic/react';
import './About.scss';

interface AboutProps { }

const About: React.FC<AboutProps> = () => {
  return (
    <IonPage id="about-page">
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>          
        </IonHeader>

        <div className="about-header mt-6 pl-4">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="about-image header-image"></div>
        </div>
        <div className="about-info">
          <h3 className="ion-padding-top ion-padding-start">About</h3>

          <p className="ion-padding-start ion-padding-end">
            Morr platform is developed for business owners to drive sales, retention, engagement and loyalty  through the use of loyalty points as a transactional currency.
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(About);