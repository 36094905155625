import React, {useState, useEffect} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {IonPage, IonContent, IonBadge, IonHeader, IonToolbar, IonButtons, IonBackButton, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonRouterLink, IonItem, IonButton} from '@ionic/react';
import { call, navigate, bicycle} from 'ionicons/icons';

import StarRatings from 'react-star-ratings';
import Gallery from "react-photo-gallery";


import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { Merchant } from '../models/Merchant';

import '../assets/scss/MerchantDetail.scss'
import '../assets/scss/global.scss'

import axios from 'axios';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import getPointsProgressClass from '../util/merchantPoints';

// fetch environment
import {morrApiURL} from '../envReader';

interface OwnProps extends RouteComponentProps {};

interface StateProps {
  merchant_id?: string;
};

interface DispatchProps {};

interface MerchantDetailProps extends OwnProps, StateProps, DispatchProps {};

const MerchantDetail: React.FC<MerchantDetailProps> = ({ merchant_id }) => {
  type photoType = {
    src: string
    width: number,
    height: number
  }
  const [merchant, setMerchant] = useState<Merchant|any>(null);
  const [photos, setPhotos] = useState<photoType|any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState<any>('');
  const [userRewardsLevel, setUserRewardsLevel] = useState(0);


  function shuffle(array:Array<any>) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  useEffect(()=>{
    let source = axios.CancelToken.source();

    function formatAMPM(date:Date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      let curr_minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + curr_minutes + ' ' + ampm;
      return strTime;
    }

    function convertToDate(nowDate:string, timeString:string){
      let newDate = new Date(nowDate +" "+ timeString + ":00");
      return newDate;
    }
    
    function findCurrentSchedule(schedules:any){
      let currentTime = new Date();
      let nowDateTime = currentTime.toISOString();
      let nowDate = nowDateTime.split('T')[0];

      schedules.forEach((schedule:any, index:number) => {
        let startTime = convertToDate(nowDate, schedule.start);
        let endTime = convertToDate(nowDate, schedule.end);

        if ((currentTime >= startTime) && (currentTime <= endTime)){
          setCurrentSchedule({
            start: formatAMPM(startTime),
            end: formatAMPM(endTime)
          });
          setIsOpen(true);
          return
        }
      })
    }

    function configurePhotoGallery(images:any){
      let gallery:photoType[] = []
      images.portrait.forEach((image:string, i:number)=>{
        let commonHeightWidth:number = i % 2 === 0 ? 1: 2
        gallery.push({
          src: image,
          width: commonHeightWidth,
          height:commonHeightWidth
        })
      });
      images.landscape.forEach((image:string, i:number)=>{
        let commonHeightWidth:number = i % 2 === 0 ? 2: 4

        gallery.push({
          src: image,
          width: commonHeightWidth*2,
          height: commonHeightWidth
        })
      })
      setPhotos(shuffle(gallery));
    }

    function getMerchantDetails(){
      axios.get(`${morrApiURL}/merchants/${merchant_id}`).then(function (response) {
        setMerchant(response.data.data);
        configurePhotoGallery(response.data.data.images);
        findCurrentSchedule(response.data.data.schedule);
        let userLevel = response.data.data.user_level;
        if (userLevel){
          setUserRewardsLevel(getPointsProgressClass(userLevel));
        }
        return response.data.data
      }).catch(function(err){
        console.log('error fetching merchant', err)
      })
    }
    getMerchantDetails()
    return () => {
      setMerchant(null);
      setPhotos([]);
      setIsOpen(false);
      setCurrentSchedule('');
      setUserRewardsLevel(0);
      source.cancel('wrap up merchant')
    };
  }, [merchant_id])


  function formatMobileNumber(counteryCode:string, mobileNumber:string){
    return `+${counteryCode}-${mobileNumber}`
  }


  if (!merchant_id || !merchant){
    return <div> Loading ... </div>
  }

  const bannerSliderSettings = {
    className: "single-image-slider",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
  }

  return (
    <IonPage id="merchant-detail">
      <IonContent>
      <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/tabs/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="cover-page">
          <img src={merchant.images.cover[0]} alt={merchant.name}/>
        </div>
        <div className="logo">
          <img src={merchant.images.profile[0]} alt={merchant.name}/>
        </div>
      <div className="content">
        <div>
          <div className="morr--heading content-title">{merchant.name}</div>
          <div className="morr--sub-heading">{merchant.name}</div>
          <div className="ratings">
            <StarRatings
              rating={merchant.rating}
              starDimension="20px"
              starSpacing="3px"
              starRatedColor='#e5a68b'
            />
          </div>
          <IonBadge color={isOpen? 'success': 'medium'} className="status">{isOpen? 'Open': 'Closed'}</IonBadge>
          {currentSchedule && <div className="current-schedule">{currentSchedule.start} - {currentSchedule.end}</div>}
          <div className="action-items">
            <IonGrid>
              <IonRow>
                <IonCol size='4'>
                  <IonRouterLink href={`tel:${formatMobileNumber(merchant.contact_numbers[0].country_code, merchant.contact_numbers[0].phone_number)}`}>
                    <IonIcon icon={call}></IonIcon>
                    <IonLabel>Call</IonLabel>
                  </IonRouterLink>
                  
                </IonCol>
                <IonCol size='4'>
                  <IonRouterLink href={`${merchant.address.google_map_link}`} target="_blank">
                    <IonIcon icon={navigate}></IonIcon>
                    <IonLabel>Location</IonLabel>
                  </IonRouterLink>
                  
                </IonCol>
                <IonCol size='4' className="disabled">
                  <IonIcon icon={bicycle}></IonIcon>
                  <IonLabel>Pickup</IonLabel>
                </IonCol>
              </IonRow>
              {merchant.website_url && <IonRow>
                <IonCol>
                  <IonButton className="morr-button" shape="round" expand="full" href={merchant.website_url} target="_blank">
                    {merchant.website_button_text || "Visit website"}
                  </IonButton>
                </IonCol>
              </IonRow>}
            </IonGrid>
          </div>
          
          <div className="divide-y-4 divide-black divide-opacity-25">
            <div className="banners">
              <Slider {...bannerSliderSettings}>
                {shuffle(merchant.images.banners).map(banner => (
                    <div key={`merchant-banner-${banner}`}>
                      <IonItem detail={false} mode="ios">
                        <img src={banner} alt={merchant.name}/>
                      </IonItem>
                    </div>
                    ))} 
              </Slider>
            </div> 
            <div className='min-w-min px-4 mt-4 mb-12 md:mb-16'>
                <h3 className="morr--heading title" style={{"margin": "2rem auto"}}>
                {merchant?.next_level_info?.heading || "Shop more for morr rewards"}
                </h3>
                {merchant.point_tiers && 
                <ProgressBar
                    filledBackground="linear-gradient(90deg, rgba(228,144,108,1) 57%, rgba(255,206,184,1) 100%)"
                    percent={userRewardsLevel}
                    >
                    <Step transition="scale">
                        {() => (<div className={`transitionStep ${userRewardsLevel >= 0  ? "accomplished" : null}`}></div>)}
                    </Step>
                    <Step transition="scale">
                        {() => (<div className={`transitionStep progressbar bronze-level ${userRewardsLevel >=30 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "bronze" ? 'active': ''}`}> {merchant.point_tiers.bronze.cashback}%</span> </div>)}
                    </Step>
                    <Step transition="scale">
                        {() => (<div className={`transitionStep progressbar silver-level ${userRewardsLevel >= 60 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "silver" ? 'active': ''}`}> {merchant.point_tiers.silver.cashback}%</span> </div>)}
                    </Step>
                    <Step transition="scale">
                        {() => (<div className={`transitionStep progressbar gold-level ${userRewardsLevel >=100 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "gold" ? 'active': ''}`}> {merchant.point_tiers.gold.cashback}%</span> </div>)}
                    </Step>
                </ProgressBar>
                }
            </div>
            <div className="recent-photos">  
            <div className="morr--heading content-title">Recent Photos</div>
              {photos.length > 0 && <Gallery photos={photos} direction={"column"} columns={3} />}
          </div>
          </div>
        </div>
      </div>
      </IonContent>
    </IonPage>
  )
};


export default connect({
  mapStateToProps: (state, ownProps) => ({
    merchant_id: selectors.getParamByName(state, ownProps, 'id')
  }),
  component: withRouter(MerchantDetail)
});
