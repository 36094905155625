import { UserActions } from './user.actions';
import { UserState } from './user.state';

function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading };
    case 'set-user-data':
      return { ...state, ...action.data };
    case 'set-username':
      return { ...state, username: action.username };
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode };
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.loggedIn };
    case 'set-authtoken':
      return { ...state, authToken: action.authToken };
    case 'set-refreshtoken':
      return { ...state, refreshToken: action.refreshToken };
  }
}

const logger = (reducer: typeof userReducer) => {
  const reducerWithLogger = (state: UserState, action: UserActions) => {
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
    return reducer(state, action);
  };
  return reducerWithLogger;
}

export const loggerReducer = logger(userReducer);