import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterLink } from '@ionic/react';
import { home,  informationCircleOutline} from 'ionicons/icons';

import { connect } from '../data/connect';
import { setDarkMode } from '../data/user/user.actions';

import './Menu.css'

const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/home', icon: home },
    { title: 'About', path: '/tabs/about', icon: informationCircleOutline }
  ],
  loggedInPages: [
    // { title: 'Account', path: '/account', icon: person },
    // { title: 'Support', path: '/support', icon: help },
    // { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    // { title: 'Login', path: '/login', icon: logIn },
    // { title: 'Support', path: '/support', icon: help },
    // { title: 'Signup', path: '/signup', icon: personAdd }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu  type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          {/* <IonListHeader>Conference</IonListHeader> */}
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Policies</IonListHeader>
          <IonRouterLink className="external-links" href="https://www.morr.my/privacy-policy" target="blank">
            Privacy Policy
          </IonRouterLink>
          <IonRouterLink className="external-links" href="https://www.morr.my/terms-and-conditions" target="blank">
            Terms & conditions
          </IonRouterLink>
          
          <IonRouterLink className="external-links" href="https://www.morr.my/fulfillment-policy" target="blank">
            Fulfillment Policy
          </IonRouterLink>
          <IonRouterLink className="external-links" href="https://www.morr.my/refund-policy" target="blank">
            Refund Policy
          </IonRouterLink>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: true
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  component: withRouter(Menu)
})
