import React, { useEffect, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonToolbar } from "@ionic/react"

import '../assets/scss/UserProfile.scss';
import { setIsLoggedIn, setUsername, setAuthToken, setRefreshToken } from '../data/user/user.actions';
import axios from 'axios'
import {rePopulateAccessToken} from '../util/authentication';

import { RouteComponentProps } from 'react-router';

import { connect } from '../data/connect';

// fetch environment
import {morrApiURL} from '../envReader';

interface StateProps {
    darkMode: boolean;
    isAuthenticated: boolean;
    authToken?: string;
}
interface DispatchProps {
    setIsLoggedIn: typeof setIsLoggedIn;
    setUsername: typeof setUsername;
    setAuthToken: typeof setAuthToken;
    setRefreshToken: typeof setRefreshToken;
  }

interface UserProfileProps extends RouteComponentProps, StateProps, DispatchProps { };

const UserProfile: React.FC<UserProfileProps> = ({history, darkMode, setIsLoggedIn, setUsername, setAuthToken, setRefreshToken, isAuthenticated, authToken}) => {
    const [user, setUser] = useState<any>(null)
    const [profileMode, setProfileMode] = useState(true)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(()=>{
        let source = axios.CancelToken.source();
        
        if (!isAuthenticated){
            history.replace('/')
            return
        }
        
        function getProfile(){
            axios.get(
                `${morrApiURL}/users/`
            ).then(function (response) {
                const user = response.data.data
                setUser(user);
                setFirstName(user.first_name);
                setLastName(user.last_name);
                setEmail(user.email);
            }).catch(function(err){
                console.error("USER_PROFILE: "+ err);
                history.replace('/')
            })
        }
        
        getProfile()

        return () => {
            setUser(null);
            source.cancel("Cancelling in cleanup");
        }
    }, [isAuthenticated, history])

    function getFullName(){
        let fullName = `${firstName} ${lastName}`
        return fullName || "Morr User"
    }
    const updateProfile = async (e: React.FormEvent) => {
        e.preventDefault();
        let payload:any = {
        }

        if (firstName && user.first_name !== firstName ){
            payload["first_name"] = firstName
        }

        if (lastName && user.last_name !== lastName ){
            payload["last_name"] = lastName
        }

        if (email && user.first_name !== email ){
            payload["email"] = email
        }

        if (password){
            payload["password"] = password
        }

        axios.patch(
            `${morrApiURL}/users/update`,
            payload
        ).then(function(response){
            setProfileMode(!profileMode);
            setUser(response.data.data);
        }).catch(function(err){
            console.log("Unable to update profile", err)
        })
    };

    function logout(){
        console.log("logging out user")
        setAuthToken(undefined);
        setRefreshToken(undefined);
        setIsLoggedIn(false);
        setUsername(undefined);
        rePopulateAccessToken("");
        history.push('/', 'root')
    }

    return (
        <IonPage id="user-profile">
            <IonContent>
                <IonHeader className="ion-no-border">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton></IonMenuButton>
                        </IonButtons>
                        <IonButtons slot="end">
                        <IonButton className="logout-button" shape="round" onClick={logout}>Logout</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {user && <div className="content-wrapper">
                    <div className="cover-page">
                        <img src={user.cover_image_url || "https://res.cloudinary.com/yuppiechef/image/upload/v1464635763/blog/yuppiechef/54_rsxl7u.jpg"} alt="a morr user"/>
                    </div>
                    <div className="logo-wrapper">
                        <div className="logo">
                            <img className="object-contain" src={user.profile_image_url || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeKta09cKhwcgEGLQpHTyhizJikgV-jG9gOw&usqp=CAU"} alt="a morr user"/>
                        </div>
                        <div className="basic-details">
                            {profileMode ? 
                                <>
                                <div className="name"> {getFullName()}</div>
                                <div className="mobile">+{user.country_code}-{user.mobile}</div>
                                <IonButton className="edit-button" shape="round" onClick={()=> {setProfileMode(!profileMode)}}>Edit</IonButton>
                                </>:
                                <div className="edit-profile">
                                    <form className="edit" onSubmit={updateProfile}>
                                        <IonList className="mx-4" style={{"background": "transparent"}}>
                                            <IonItem>
                                                <IonLabel position="floating" mode="ios">First Name</IonLabel>
                                                <IonInput name="first_name" type="text" required value={firstName} onIonChange={e => setFirstName(e.detail.value!)} />
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Last Name</IonLabel>
                                                <IonInput name="last_name" type="text" required value={lastName} onIonChange={e => setLastName(e.detail.value!)} />
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating" color="light">Email</IonLabel>
                                                <IonInput name="email" type="email" required inputmode="email" value={email} onIonChange={e => setEmail(e.detail.value!)} />
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Password</IonLabel>
                                                <IonInput name="password" type="password" autocomplete={"off"} value={password} onIonChange={e => setPassword(e.detail.value!)} />
                                            </IonItem>
                                        </IonList>
                                        <div className="submit">
                                            <IonButton style={{"background": "transparent", "-webkit-appearance": "none"}} 
                                            type="submit" className="edit-button" shape="round">Save and Continue</IonButton>
                                        </div>
                                    </form>
                                    
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            </IonContent>
        </IonPage>
    )
}



export default connect<StateProps>({
    mapStateToProps: (state) => ({
      darkMode: state.user.darkMode,
      isAuthenticated: state.user.isLoggedin,
      authToken: state.user.authToken,
    }),
    mapDispatchToProps: {setIsLoggedIn, setUsername, setAuthToken, setRefreshToken },
    component: UserProfile
});