
import { BarcodeScanner } from '@ionic-native/barcode-scanner';


async function scanCode(){
    const camera_options = {
        showFlipCameraButton : false,
        showTorchButton : true,
        disableSuccessBeep: true,
        format: 'QR_CODE'
    }
    let data = {}

    try {
        data = await BarcodeScanner.scan(camera_options);
    } catch(err){
        alert('\nError:'+err);
    }
    
    return RedirectOrRender(data);
}

const RedirectOrRender = (data:any) => {

    if (!data.cancelled){
        type responseType = {
            merchantUid: string,
            merchantPageUri: string
        }
        let response: responseType = {
            merchantUid: '',
            merchantPageUri: ''
        }

        try {
            response = JSON.parse(data.text);
        }catch(err){
            console.log("Invalid QR Code!")
        }
        
        if (!response.merchantUid){
            response = {merchantUid: "0e9cc51a-cf1a-45f1-8c74-d0f32465a44c", merchantPageUri: "https://app.morr.my/tabs/payments/pay/0e9cc51a-cf1a-45f1-8c74-d0f32465a44c"}
        }
        
        if (response.hasOwnProperty('merchantUid')){
            let payment_url = `/tabs/payments/pay/${response.merchantUid}`;
            return payment_url
        }else {
            alert("Invalid QR Code!");
        }
    }
    return '/tabs/home'
}

export default scanCode;