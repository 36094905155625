import React, {useEffect, useState} from 'react';
import {IonPage, IonContent, IonIcon, IonLabel, IonButton, IonItem} from '@ionic/react';
import { location, pieChartOutline} from 'ionicons/icons';

import '../assets/scss/global.scss'
import '../assets/scss/Home.scss'

import { Merchant } from '../models/Merchant';
import getPointsProgressClass from '../util/merchantPoints';
import {shuffle} from '../util/extensions';
import { connect } from '../data/connect';
import axios from "axios";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import StarRatings from 'react-star-ratings';

// fetch environment
import {morrApiURL} from '../envReader';

interface StateProps {
    darkMode: boolean;
    isAuthenticated: boolean;
    authToken?: string;
}

interface HomeProps extends StateProps { };

const Home: React.FC<HomeProps> = ({darkMode, isAuthenticated, authToken}) => {
    const [merchants, setMerchants] = useState<Merchant[]>([])
    const [walletTotal, setWalletTotal] = useState(0)
    
    useEffect(()=>{
        let source = axios.CancelToken.source();
        axios.get(`${morrApiURL}/merchants/`).then(merchants => {
            setMerchants(merchants.data.data);
        }).catch(err=> console.log("error while fetching", err));

        if (isAuthenticated && authToken){
            axios.get(`${morrApiURL}/rewards/points/all`).then(rewardPoints => {
                setWalletTotal(rewardPoints.data.total)
            }).catch(err=> console.log("error while fetching", err));
        }

        return () => {
            setMerchants([]);
            setWalletTotal(0)
            source.cancel("Cancelling in cleanup");
        }
    }, [isAuthenticated, authToken])

    const mostVisitedSettings = {
        className: "center-mode-slider",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        dots: false,
    };

    const mostPopularSettings = {
        className: "single-image-slider",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        dots: true,
    }

    return (
        <IonPage id="home-page">
            <IonContent>
                <div className='morr-header-wrapper'>
                    <div className='morr-logo'>
                        <img className='logo' src='/assets/icon/morr-white-transparent.png' alt="Welcome to Morr"></img>
                    </div>
                    {(isAuthenticated && (walletTotal >= 0)) && <div className="wallet-points">
                        <IonButton routerLink="/tabs/reward-points" className="rewards-link" buttonType="string">
                            <IonLabel>{walletTotal}</IonLabel>
                            <IonIcon icon={pieChartOutline}></IonIcon>
                        </IonButton>
                    </div>}
                </div>


                <div className="most-popular-list">
                    <Slider {...mostPopularSettings}>
                    {shuffle(merchants).map(merchant => (
                        <div key={`most-popular-${merchant.merchant_id}`}>
                            <IonItem detail={false} mode="ios" routerLink={`/tabs/merchants/${merchant.merchant_id}`}>
                                <img src={(merchant.images.banners.length > 0) ? merchant.images.banners[0] : ''} alt={merchant.name}/>
                            </IonItem>
                        </div>
                        ))}
                    </Slider>
                </div>
                <div className="most-visited-list">
                    <h5 className='morr--heading slide-heading ml-3'>Most visited</h5>
                    <Slider {...mostVisitedSettings}>
                    {merchants.map(merchant => (
                            <div key={`most-visited-${merchant.merchant_id}`} >
                                <IonItem className="min-w-min p-0 shadow-md rounded-lg" detail={false} mode="ios" routerLink={`/tabs/merchants/${merchant.merchant_id}`}>
                                    <div className='item-wrapper'>
                                        <div className="header">
                                            <div className='morr--heading title'>
                                                {merchant.name}
                                            </div>
                                        </div>
                                        <div className='merchant-thumb'>
                                            <img src={(merchant.images.profile.length > 0) ? merchant.images.profile[0] : ''} alt={merchant.name} className="w-1/4" />
                                            <span className='morr--normal-text'>{merchant?.next_level_info?.heading || "Shop more for morr rewards"}</span>
                                        </div>
                                        <div className='points-progress w-11/12 mt-6'>
                                            {merchant.point_tiers && 
                                                <ProgressBar
                                                filledBackground="linear-gradient(90deg, rgba(228,144,108,1) 57%, rgba(255,206,184,1) 100%)"
                                                percent={getPointsProgressClass(merchant.user_level)}
                                                >
                                                    <Step transition="scale">
                                                        {() => (<div className={`transitionStep ${getPointsProgressClass(merchant.user_level) >= 0  ? "accomplished" : null}`}></div>)}
                                                    </Step>
                                                    <Step transition="scale">
                                                        {() => (<div className={`transitionStep progressbar bronze-level ${getPointsProgressClass(merchant.user_level) >=30 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "bronze" ? 'active': ''}`}> {merchant.point_tiers.bronze.cashback}%</span> </div>)}
                                                    </Step>
                                                    <Step transition="scale">
                                                        {() => (<div className={`transitionStep progressbar silver-level ${getPointsProgressClass(merchant.user_level) >= 60 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "silver" ? 'active': ''}`}> {merchant.point_tiers.silver.cashback}%</span> </div>)}
                                                    </Step>
                                                    <Step transition="scale">
                                                        {() => (<div className={`transitionStep progressbar gold-level ${getPointsProgressClass(merchant.user_level) >=100 ? "accomplished" : null}`}> <span className={`desc ${merchant.user_level === "gold" ? 'active': ''}`}> {merchant.point_tiers.gold.cashback}%</span> </div>)}
                                                    </Step>
                                                </ProgressBar>
                                            }
                                        </div>
                                    </div>
                                </IonItem>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="near-me-list">
                    <h5 className='morr--heading slide-heading  ml-3'>Near Me</h5>
                    <div className='flex justify-between flex-initial flex-wrap break-words'>
                            {shuffle(merchants).map(merchant => (
                                <div className="xs:w-1/2 sm:w-1/3 md:w-1/4 lg:1/6 my-2" key={merchant.merchant_id + "near-me"}>
                                    <IonItem className="w-11/12 m-auto p-0 shadow-md rounded-lg" detail={false} mode="ios" routerLink={`/tabs/merchants/${merchant.merchant_id}`}>
                                        <div className="info">
                                            <div className='thumb'>
                                                <img src={merchant.images.portrait[0]} alt={merchant.name} title={merchant.name} /> 
                                            </div>
                                            <div className="description p-2">
                                                <div className='font-sans text-lg'>{merchant.name}</div>
                                                <div className='ratings text-xs'>
                                                    <StarRatings
                                                    rating={merchant.rating}
                                                    starDimension="20px"
                                                    starSpacing="3px"
                                                    starRatedColor='#e5a68b'
                                                    />
                                                </div>
                                                <div className="text-sm">
                                                    <IonIcon icon={location} /> 
                                                    <span className='distance '>{merchant.distance}</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </IonItem>
                                </div>
                            ))}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
};


export default connect<StateProps>({
    mapStateToProps: (state) => ({
        darkMode: state.user.darkMode,
        isAuthenticated: state.user.isLoggedin,
        authToken: state.user.authToken
    }),
    component: Home
})