import React, { useEffect, useContext } from 'react';
import { IonPage,  IonCardContent, IonRouterContext} from '@ionic/react';
import * as selectors from '../data/selectors';
import { connect } from '../data/connect';

import { RouteComponentProps, withRouter } from 'react-router';

import '../assets/scss/PaymentProcessor.scss';
import axios from 'axios';

// fetch environment
import {morrApiURL} from '../envReader';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  statusCode: string
  paymentID: string
  paymentOTP: string
}

interface DispatchProps {}

type PaymentProps = OwnProps & StateProps & DispatchProps;

const PaymentProcessor: React.FC<PaymentProps> = ({ statusCode, paymentID, paymentOTP}) => {

  const ionRouterContext = useContext(IonRouterContext);

  useEffect(() => {
    
    let source = axios.CancelToken.source();

    const intervalId = setInterval(() => {
      axios({
        method: "POST",
        url: `${morrApiURL}/payments/direct/status`,
        data: {
          "transaction_id": paymentID
        },
      })
      .then(function (response) {
        let status = response.data.data.status !== "4202";
        if (status){
          console.log("Payment completed", status, response.data);
          ionRouterContext.push(`/tabs/payments/process/${response.data.data.status}/${paymentID}`, 'forward', "replace");
        }
        
      }).catch((err)=>{
        console.log("PAY_DIRECT_STATUS_FAILED", err);
        // clearInterval(intervalId);
      })
    }, 5000)
    
    return () => {
      source.cancel("let's wrap up");
      clearInterval(intervalId);
    }

  }, [paymentID, ionRouterContext])


  return (
      <IonPage id="PayWithCashVerification">
          <IonCardContent>
            <div className="flex flex-wrap w-full justify-center text-center mt-16 p-4 leading-loose">
              <div className='morr--heading title text-xl uppercase mb-14'> Pay with Cash</div>
              <div className="text-lg my-4">Share following code with Merchant to make payment</div>
              <div className="text-4xl font-extrabold my-8">{paymentOTP}</div>
            </div>
          </IonCardContent>
      </IonPage>
  );
};
    
export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    statusCode: selectors.getParamByName(state, OwnProps, 'statusCode'),
    paymentID: selectors.getParamByName(state, OwnProps, 'paymentID'),
    paymentOTP: selectors.getParamByName(state, OwnProps, 'paymentOTP'),
  }),
  component: withRouter(PaymentProcessor)
})