export default function getPointsProgressClass(currentLevel:string, userLevel:string="default"){
    const levels:any = {
        "default": 0,
        "bronze": 35,
        "silver": 70,
        "gold": 100
    }

    return levels[currentLevel]

}

export function getNextLevel(currentLevel:string){
    let nextLevel = "bronze"
    switch (currentLevel){
      case "bronze":
        nextLevel = "silver"
        break
      case "silver":
        nextLevel = "gold"
        break
      case "gold":
        nextLevel = "gold"
        break
      default:
        nextLevel = "bronze"
    }

    return nextLevel
}